import React, {useEffect, useState} from "react"
import Link from "next/link";

export default function Error({code = 500}) {

    const errors = {
        404: {
            'title': 'Страница не найдена',
            'text': 'но найдено расположение внутри ТТК',
            'code': '<span>4</span><span>0</span><span>4</span>'
        },
        500: {
            'title': 'Ошибка сервера',
        },
    }

    const images = [
        {id:1, path: '/files/images/errors/404.jpg'},
        {id:2, path: '/files/images/errors/505.jpg'},
        {id:3, path: '/files/images/errors/500.jpg'},
    ]

    const [activeImage, setActiveImage] = useState({id:1, path: '/files/images/errors/404.jpg'})


    useEffect(()=> {
        let timerId;
        if(window.innerWidth >= 1000){
            let i = 0;
            timerId = setInterval(()=> {
                setActiveImage(images[i])
                i++
                if(i === images.length){
                    i = 0
                }
            }, 8000)
        }else {
            timerId && clearInterval(timerId)
        }
        window.addEventListener('resize', () => {
            if(window.innerWidth < 1000){
                timerId && clearInterval(timerId)
            }
        })
    }, [])

    return (
        <>
            <section className='section'>
                <div className="block">
                    <h1 className='title-top'>{code}</h1>
                    <h2 className='title-middle'>{errors[code]['title']}</h2>
                    {errors[code]['text'] && <h3 className='title-bottom'>{errors[code]['text']}</h3>}
                    <div className='action'>
                        <Link href={"/"}><a className="btn">На главную</a></Link>
                    </div>
                    {
                        code === 404 && <div className="pic" style={{left: 'calc(50% - 165px)'}}>
                            <img className="animation" src={activeImage.path} alt={errors[code]['title']}/>
                        </div>
                        ||
                        <div className="pic" style={{left: 'calc(50% - 190px)'}}>
                            <img className="animation" src={activeImage.path} alt={errors[code]['title']}/>
                        </div>
                    }
                </div>
            </section>
            <style jsx>{`
              .section {
                background: linear-gradient(180deg, #ABC3D5 0%, rgba(238, 243, 247, 0) 100%) top no-repeat;
                background-size: 100% 208px;
                padding-top: 110px;
                max-width: none;
              }
              .block {
                max-width: 1030px;
                margin: 80px auto;
                text-align: center;
                background: none;
                position: relative;
              }
              .block::before {
                  content: none;
                }
              .title-top {
                font-weight: 300;
                font-size: 438px;
                line-height: 1;
                color: #23303F;
                text-transform: uppercase;
                letter-spacing: 25px;
              }

              .title-middle {
                color: #1E324B;
                text-transform: uppercase;
                font-size: 20px;
                font-weight: 400;
                margin-bottom: 10px;
              }

              .title-bottom {
                font-size: 20px;
                font-weight: 400;
                margin-bottom: 40px;
                text-transform: uppercase;
                color: rgba(30, 50, 75, 0.3);
              }
              .pic {
                width: 318px;
                height: 318px;
                overflow: hidden;
                -webkit-transform: translateZ(0);
                -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
                border-radius: 50%;
                 -webkit-border-radius: 50%;
                 -moz-border-radius: 50%;
                position: absolute;
                top: 50px;
                
              }
              .animation {
                animation: move 8s infinite 1s alternate linear;
              }
              @keyframes move {
                    from {
                      transform: translateX(0%);
                    }
                    to {
                      transform: translateX(-30%);
                    }
                }
                @media all and (max-width: 1000px){
                  .pic {
                    display: none;
                  }
                  .title-top {
                    font-size: 36vw;
                  }
                }
                @media all and (max-width: 600px){
                  .title-top {
                    font-size: 30vw;
                  }
                  .title-middle {
                    font-size: 16px;
                  }
                  .title-bottom {
                    font-size: 16px;
                  }
                }
            `}</style>
        </>
    )
}