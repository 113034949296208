import { GetAvailablePages } from "../actions/Header";
import Error from "../components/layout/Error";
import Layout from "../components/layout/Layout";
import Footer from "../components/layout/footer/Footer";
import HeadCustom from "../components/layout/head/HeadCustom";
import Header from "../components/layout/header/Header";

export default function Error404({ seo, visibility_map }) {
  return (
    <>
      <HeadCustom title={ seo ? seo.title : '' } description={ seo ? seo.description : '' }
                  ogTitle={ seo ? seo.og_title : '' } ogDesc={ seo ? seo.og_description : '' }
                  ogImg={ seo ? seo.og_image : '' }>
        <Header visibility_map={ visibility_map }/>
        <Error code={ 404 }/>
        <Footer/>
      </HeadCustom>
    </>
  )
}

export async function getStaticProps() {
  const visibility_map = await GetAvailablePages()

  const res2 = await fetch(`${ process.env.ABSOLUT_PATH }pageinfo/?url=/404/`)
  const seo = res2.status === 200 ? await res2.json() : ''
  return {
    props: { seo, visibility_map }
  }
}